import React, { useState, FunctionComponent } from 'react';
import { graphql, Link } from 'gatsby';
import classnames from 'classnames';

import Layout from '../components/Layout';
import Image from '../components/Image';
import SEO from '../components/SEO';
import { Podcast } from '../types';
import PodcastCardFull from '../components/PodcastCardFull';
import '../styles/radio.scss';

interface Props {
  data: {
    allContentfulPodcast: {
      edges: {
        node: Podcast;
      }[];
    };
  };
  pageContext: {
    nextPagePath: string;
    numberOfPages: number;
    pageNumber: number;
    previousPagePath: string;
  };
}

const PodcastPage: FunctionComponent<Props> = (props: Props) => {
  const [activePodcastId, setActivePodcastId] = useState('');

  const { data, pageContext } = props;

  return (
    <Layout>
      <SEO title="Ever Forward Radio" />

      <div className="hero">
        <Image className="hero__image right-mobile" fileName="radio-hero.png" />

        <div className="hero__overlay" />

        <div className="hero__content">
          <div className="wrapper">
            <h1>Coach. Podcaster. COACHCASTER.</h1>

            <p className="hero__blurb">Health coach turned podcaster, Chase interviews industry leaders in fitness nutrition and mindset. Messages to help you live a life EVER FORWARD.</p>

            <h2>Chase Chewning, MS, ACE CHC</h2>

            <div className="actions">
              <a
                href="/https://podcasts.apple.com/gb/podcast/ever-forward-radio/id1195967486"
                className="button button--blue"
                target="_blank"
                rel="noreferrer noopener"
              >
                Listen on Apple Podcasts
              </a>

              <p>or</p>

              <Link to="/contact" className="button button--white">Contact Me</Link>
            </div>
          </div>
        </div>
      </div>

      <section className="container">
        <h2 className="text-center section-header">Listen On</h2>

        <div className="platform-icons">
          <a
            href="https://podcasts.apple.com/us/podcast/ever-forward-radio/id1195967486"
            className="platform-icons__elem"
            title="Listen to Ever Forward Radio on Apple Podcasts"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image fileName="Apple_Podcast_Icon.png" />

            Apple Podcast
          </a>

          <a
            href="https://podcasts.google.com/?feed=aHR0cHM6Ly9ldmVyZm9yd2FyZHJhZGlvLmxpYnN5bi5jb20vcnNz"
            className="platform-icons__elem"
            title="Listen to Ever Forward Radio on Google Podcasts"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image fileName="Google_Podcasts_Icon.png" />

            Google Podcast
          </a>

          <a
            href="https://open.spotify.com/show/4vk2K6L7FuKYxqmL9YaoY5"
            className="platform-icons__elem"
            title="Listen to Ever Forward Radion on Spotify"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image fileName="Spotify_Icon.png" />

            Spotify
          </a>
        </div>
      </section>

      <section className="container">
        <h2 className="text-center section-header">Episodes</h2>

        {pageContext.pageNumber === 0 && data.allContentfulPodcast.edges.length > 0 ? (
          <>
            <h3 className="section-sub-header section-sub-header--grey">Latest Episode</h3>

            <PodcastCardFull
              activePodcastId={activePodcastId}
              expandable
              podcast={data.allContentfulPodcast.edges[0].node}
              onPodcastPlayStateChanged={(id) => setActivePodcastId(id)}
            />
          </>
        ) : null}

        <h3 className="section-sub-header section-sub-header--grey">Previous Episodes</h3>

        {data.allContentfulPodcast.edges.slice(pageContext.pageNumber === 0 ? 1 : 0).map((elem) => (
          <PodcastCardFull
            key={elem.node.id}
            activePodcastId={activePodcastId}
            expandable
            podcast={elem.node}
            onPodcastPlayStateChanged={(id) => setActivePodcastId(id)}
          />
        ))}
      </section>

      <div className="pagination-links">
        <Link
          to={pageContext.previousPagePath}
          className={classnames('pagination-links__elem', { 'disabled-link': pageContext.previousPagePath === '' })}
          onClick={(event) => {
            if (event.currentTarget.className.indexOf('disabled-link') > -1) {
              event.preventDefault();
            }
          }}
        >
          Previous
        </Link>

        <Link
          to={pageContext.nextPagePath}
          className={classnames('pagination-links__elem', { 'disabled-link': pageContext.nextPagePath === '' })}
          onClick={(event) => {
            if (event.currentTarget.className.indexOf('disabled-link') > -1) {
              event.preventDefault();
            }
          }}
        >
          Next
        </Link>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulPodcast(skip: $skip, limit: $limit, sort: {fields: [publishedDate], order: DESC}) {
      edges {
        node {
          audioUrl
          content {
            json
          }
          id
          primaryImage {
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid
            }
          }
          publishedDate
          slug
          title
        }
      }
    }
  }
`;

export default PodcastPage;
